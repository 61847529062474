import {Container,Row, Col,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import Pageart from "../components/pageart/pageart"
import SEO from "../components/seo"
import ModalOverlay from "../components/modal/modal.js"
import PlayButton from "../images/play.svg"

import generationalDivide from "../pdfs/JAN-22-Amarin-Harris-Poll-Infographic-The-Generational-Divide.pdf";
import genderGap from "../pdfs/JAN-22-Amarin-Harris-Poll-Infographic-The-Gender-Gap.pdf";
import understandingRisks from "../pdfs/JAN-22-Amarin-Harris-Poll-Infographic-Understanding-The-Risks.pdf";

const HeartVideos = () => (
  <Layout>
    <div className="heart-videos">
      <SEO
        title="Heart Videos"
        description="Watch videos about cardiovascular disease risk. Get the facts and learn how to help protect yourself and your loved ones from heart attack or stroke."
      />

      <Pageart content="HEART VIDEOS:" content2="GET THE FACTS"></Pageart>

      <Container fluid className="page-container">
        <Row>
          <Col className="content-wrapper podcasts">
            <p>
              Whether you’re young or old, male or female, everybody should
              understand the risks of cardiovascular disease, including heart
              attack or stroke.
            </p>

            <p>Watch the videos below to get the facts.</p>

            <p className="callout callout-no-bottom">
              <strong className="secondary">
                WBRC Channel 6 News Talks Cardiovascular Health with Former NFL
                QB
              </strong>
            </p>
            <div class="video-play-wrapper generational">
              <img
                src={PlayButton}
                class="video-play-button extra-top-margin"
              />
              <iframe
                className="heart-video"
                src="https://player.vimeo.com/video/688485782?h=f6726d237d&api=1"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>

            <p className="callout callout-no-bottom">
              <strong className="secondary">
                Cardiovascular Risk and the Generational Divide
              </strong>
            </p>
            <div class="video-play-wrapper generational">
              <img src={PlayButton} class="video-play-button" />
              <iframe
                className="heart-video"
                src="https://player.vimeo.com/video/672383694?h=9721a88035&api=1"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
              <p>If you liked the video, download the data infographic.</p>
              {/* <div className='text-center'><a href="#" class="btn btn-primary text-decoration-none d-inline-block m-auto m-b-20">DOWNLOAD</a></div> */}
              <div className="text-center">
                <a
                  href={generationalDivide}
                  class="btn btn-primary text-decoration-none d-inline-block m-auto m-b-30"
                >
                  DOWNLOAD PDF
                </a>
              </div>
            </div>

            <p className="callout callout-no-bottom">
              <strong className="secondary">
                Cardiovascular Risk and the Gender Gap
              </strong>
            </p>
            <div className="video-play-wrapper gender">
              <img src={PlayButton} className="video-play-button" />
              <iframe
                className="heart-video"
                src="https://player.vimeo.com/video/672383674?h=594c3aa863&api=1"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
              <p>If you liked the video, download the data infographic.</p>
              {/* <div className='text-center'><a href="#" class="btn btn-primary text-decoration-none d-inline-block m-auto m-b-20">DOWNLOAD</a></div> */}
              <div className="text-center">
                <a
                  href={genderGap}
                  class="btn btn-primary text-decoration-none d-inline-block m-auto m-b-30"
                >
                  DOWNLOAD PDF
                </a>
              </div>
            </div>

            <p className="callout callout-no-bottom">
              <strong className="secondary">
                Understanding Cardiovascular Risks{" "}
              </strong>
            </p>
            <div className="video-play-wrapper understanding">
              <img src={PlayButton} className="video-play-button" />
              <iframe
                className="heart-video"
                src="https://player.vimeo.com/video/672383710?h=0d0a67cbe5&api=1"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
              <p>If you liked the video, download the data infographic.</p>
              {/* <div className='text-center'><a href="#" class="btn btn-primary text-decoration-none d-inline-block m-auto m-b-20">DOWNLOAD</a></div> */}
              <div className="text-center">
                <a
                  href={understandingRisks}
                  class="btn btn-primary text-decoration-none d-inline-block m-auto m-b-30"
                >
                  DOWNLOAD PDF
                </a>
              </div>
            </div>

            <hr className="grey" />

            <p className="louder pt-5">
              <strong className="black">TALK TO YOUR DOCTOR TODAY</strong> ABOUT
              SWITCHING TO FDA-APPROVED THERAPIES, AS YOU MAY STILL BE AT RISK
              OF HAVING A HEART ATTACK OR STROKE.
            </p>

            <p className="text-center">
              <ModalOverlay
                message={[
                  <strong>You are now leaving</strong>,
                  <br />,
                  "TruetoYourHeart.com",
                  <br />,
                  <p className="mobile-text-300">
                    You will be directed to another Amarin website to learn
                    about a prescription cardiovascular treatment.
                  </p>,
                ]}
                buttontext="Continue"
                link="https://vascepa.com/"
              >
                <span class="btn btn-primary text-decoration-none">
                  LEARN MORE
                </span>
              </ModalOverlay>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

export default HeartVideos
